import React from 'react';
import { useTheme } from 'emotion-theming';
import { css } from 'emotion';

export default ({ colour = null }) => {
    const theme = useTheme();
    return (
        <svg
            className={css`
                fill: ${colour || theme.colours.gray};
            `}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 65 30"
            width="18"
            height="18"
        >
            <path d="M 25.941406 2 C 25.464844 2.027344 25.074219 2.386719 25.007813 2.859375 C 24.941406 3.332031 25.21875 3.785156 25.671875 3.945313 C 32.292969 6.261719 37 12.566406 37 20 C 37 29.402344 29.402344 37 20 37 C 12.566406 37 6.261719 32.292969 3.945313 25.671875 C 3.78125 25.203125 3.296875 24.925781 2.8125 25.015625 C 2.324219 25.109375 1.980469 25.546875 2 26.042969 C 2.515625 38.28125 12.640625 48 25 48 C 37.691406 48 48 37.691406 48 25 C 48 12.640625 38.28125 2.515625 26.042969 2 C 26.007813 2 25.972656 2 25.941406 2 Z M 31.84375 5.203125 C 40.074219 8.039063 46 15.78125 46 25 C 46 36.609375 36.609375 46 25 46 C 15.78125 46 8.039063 40.074219 5.203125 31.84375 C 8.691406 36.183594 14 39 20 39 C 30.480469 39 39 30.480469 39 20 C 39 14 36.183594 8.691406 31.84375 5.203125 Z"></path>
        </svg>
    );
};
